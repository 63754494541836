/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // Show/Hide Back To Top Button
  $(window).scroll(function() {
    if ($(document).scrollTop() > 150) {
      $('.navbar').addClass('shrink');
      $('.navbar').removeClass('normal');
      $('.back-top').addClass('visible');
    } else {
      $('.navbar').removeClass('shrink');
      $('.navbar').addClass('normal');
      $('.back-top').removeClass('visible');
    }
  });

  // Smooth scroll to anchor
  $(".toscroll a").on('click',function(e) {
    var url = e.target.href;
    var hash = url.substring(url.indexOf("#")+1);
    $('html, body').animate({
        scrollTop: $('#'+hash).offset().top
    }, 500);
    return false;
  });

  function matchHeight(className) {
      var div = document.getElementsByTagName("matchHeight");
      var tallest = 0;
      // Loop over matching divs
      for(i = 0; i < div.length; i++)
      {
          var ele = div[i];
          var eleHeight = ele.offsetHeight;
          tallest = (eleHeight>tallest ? eleHeight : tallest); /* look up ternary operator if you dont know what this is */
      }
      var findClass = document.getElementsByClassName(className);
      for(i = 1; i < findClass.length; i++)
      {
          findClass[i].style.height = tallest + "px";
      }
  }

  // var mainHeader = $('.as16-auto-hide-header'),
  //   secondaryNavigation = $('.as16-secondary-nav'),
  //   //this applies only if secondary nav is below intro section
  //   belowNavHeroContent = $('.sub-nav-hero'),
  //   headerHeight = mainHeader.height();
  
  // //set scrolling variables
  // var scrolling = false,
  //   previousTop = 0,
  //   currentTop = 0,
  //   scrollDelta = 10,
  //   scrollOffset = 250;

  // mainHeader.on('click', '.nav-trigger', function(event){
  //   // open primary navigation on mobile
  //   event.preventDefault();
  //   mainHeader.toggleClass('nav-open');
  // });

  // $(window).on('scroll', function(){
  //   if( !scrolling ) {
  //     scrolling = true;
  //     (!window.requestAnimationFrame)
  //       ? setTimeout(autoHideHeader, 250)
  //       : requestAnimationFrame(autoHideHeader);
  //   }
  // });

  // $(window).on('resize', function(){
  //   headerHeight = mainHeader.height();
  // });

  // function autoHideHeader() {
  //   var currentTop = $(window).scrollTop();

  //   ( belowNavHeroContent.length > 0 ) 
  //     ? checkStickyNavigation(currentTop) // secondary navigation below intro
  //     : checkSimpleNavigation(currentTop);

  //     previousTop = currentTop;
  //   scrolling = false;
  // }

  // function checkSimpleNavigation(currentTop) {
  //   //there's no secondary nav or secondary nav is below primary nav
  //     if (previousTop - currentTop > scrollDelta) {
  //       //if scrolling up...
  //       mainHeader.removeClass('is-hidden');
  //     } else if( currentTop - previousTop > scrollDelta && currentTop > scrollOffset) {
  //       //if scrolling down...
  //       mainHeader.addClass('is-hidden');
  //     }
  // }

  // function checkStickyNavigation(currentTop) {
  //   //secondary nav below intro section - sticky secondary nav
  //   var secondaryNavOffsetTop = belowNavHeroContent.offset().top - secondaryNavigation.height() - mainHeader.height();
    
  //   if (previousTop >= currentTop ) {
  //       //if scrolling up... 
  //       if( currentTop < secondaryNavOffsetTop ) {
  //         //secondary nav is not fixed
  //         mainHeader.removeClass('is-hidden');
  //         secondaryNavigation.removeClass('fixed slide-up');
  //         belowNavHeroContent.removeClass('secondary-nav-fixed');
  //       } else if( previousTop - currentTop > scrollDelta ) {
  //         //secondary nav is fixed
  //         mainHeader.removeClass('is-hidden');
  //         secondaryNavigation.removeClass('slide-up').addClass('fixed'); 
  //         belowNavHeroContent.addClass('secondary-nav-fixed');
  //       }
        
  //     } else {
  //       //if scrolling down...  
  //       if( currentTop > secondaryNavOffsetTop + scrollOffset ) {
  //         //hide primary nav
  //         mainHeader.addClass('is-hidden');
  //         secondaryNavigation.addClass('fixed slide-up');
  //         belowNavHeroContent.addClass('secondary-nav-fixed');
  //       } else if( currentTop > secondaryNavOffsetTop ) {
  //         //once the secondary nav is fixed, do not hide primary nav if you haven't scrolled more than scrollOffset 
  //         mainHeader.removeClass('is-hidden');
  //         secondaryNavigation.addClass('fixed').removeClass('slide-up');
  //         belowNavHeroContent.addClass('secondary-nav-fixed');
  //       }

  //     }
  // }

})(jQuery); // Fully reference jQuery after this point.
